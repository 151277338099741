import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { FormattedMessage, injectIntl, IntlShape } from 'gatsby-plugin-intl';

import { Layout } from '../components';
import './resume.scss';

interface ResumeProps {
  intl: IntlShape;
}

const Resume: React.FC<ResumeProps> = ({ intl }) => {
  const query = useStaticQuery(graphql`
    {
      pdfResume: allFile(filter: { extension: { eq: "pdf" } }) {
        edges {
          node {
            publicURL
            name
          }
        }
      }
      resume1: file(relativePath: { eq: "assets/images/resume1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      resume2: file(relativePath: { eq: "assets/images/resume2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout title={intl.formatMessage({ id: 'navigation.pages.resume' })} className="resume-layout">
      <div className="container">
        <a href={query.pdfResume.edges[0].node.publicURL} className="pdf-title">
          <h4>
            <FormattedMessage id="resume" />
          </h4>
        </a>
        <div className="pdf-container">
          <Img fluid={query.resume2.childImageSharp.fluid} className="pdf pdf2" />
          <Img fluid={query.resume1.childImageSharp.fluid} className="pdf pdf1" />
        </div>
      </div>
    </Layout>
  );
};

export default injectIntl(Resume);
